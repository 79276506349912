import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Home`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <div className="main-content">
        <strong>Welcome</strong>, you're looking at a generative artpiece!{" "}
        <br></br>
        It is a 2D abstract expression of the spacetime structure, and where
        energies flow through bending the structure itself.
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
